.marker {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -105%);
  background-color: rgba(255, 255, 255);
  padding: 5px;
  border-radius: 10px;
  font-size: 12px;
  color: black;
  z-index: 1000;
  cursor: pointer;
  transition: 0.4s ease;
}

.marker .serial-number {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70px;
  display: inline-block;
}

.marker:hover .serial-number {
  max-width: 200px;
}
