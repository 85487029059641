body {
  font-family: Kanit, Roboto, Inter, "Helvetica Neue", sans-serif;
  color: white !important;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

textarea {
  font-family: Kanit, Roboto, Inter, "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
/* ::-webkit-scrollbar-track {
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(53, 73, 255, 0.5);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(53, 73, 255, 0.5);
}

/* Override Style*/
a {
  text-decoration: none;
  color: inherit;
}
/* Base */
.font-300 {
  font-weight: 300 !important;
}
.font-400 {
  font-weight: 400 !important;
}
.font-500 {
  font-weight: 500 !important;
}
.font-600 {
  font-weight: 600 !important;
}
.font-700 {
  font-weight: 700 !important;
}

.font-size-12 {
  font-size: 12px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}

.cursor-pointer {
  cursor: pointer !important;
}

h1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 4px 0;
}
h2 {
  font-family: Kanit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 4px 0;
}

h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 4px 0;
}

/* Input */
::placeholder {
  color: #9ca3af !important;
  font-family: Kanit;
  font-weight: 300;
}
.text-input-field-component {
  .text-component {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .text-input-component {
    background-color: white;

    height: 42px !important;
  }
  .text-input-component input[disabled] {
    cursor: not-allowed;
  }
  /* .text-input-component input[disabled] {
    border-radius: 12px;
    background-color: rgba(236, 236, 239, 0.7);
  }

  .text-input-component input {
    border-radius: 12px;
    background-color: white;
  } */

  .text-input-component input {
    font-family: Kanit;
  }
}
/* error message */
.helpers {
  .text-component {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
/* Modal */
.ReactModalPortal {
  position: absolute;
  z-index: 1;
}

/* rc-table */
.rc-table table {
  width: 100%;
  border-spacing: 0px;
}
.rc-table th,
.rc-table td {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  padding: 16px 8px;
  white-space: normal;
  word-break: break-word;
  transition: box-shadow 0.3s;
}
.rc-table th,
.rc-table td,
.rc-table td:last-child {
  border: 1px solid #6b7280;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

/* select */
select:has(option:disabled:checked[hidden]) {
  color: gray;
}

select option {
  color: black;
}
.error-message {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #db5959;
}

.button-component {
  box-shadow: none !important;
  min-height: 44px !important;
  border-radius: 10px !important;
  padding: 0 16px !important;
  gap: 0 !important;
}

.button-component.register-button-active {
  border-radius: 8px;
  background-color: #ffb32c;
  padding: 12px 16px;
}
.button-component.register-button-active:hover {
  background-color: #ffb32c;
}

.button-component.register-button-active:disabled {
  background-color: #ffb32c;
}

.button-component.register-button {
  border-radius: 8px;
  opacity: 0.5;
  background-color: #ffc256;
  padding: 12px 16px;
  color: white;
}
.button-component.register-button:hover {
  background-color: #ffc256;
}
.button-component.register-button:disabled {
  background-color: #ffc256;
}

.footer {
  z-index: 10;
  display: flex;
  width: 100%;
  height: 112px;
  padding: 8px 32px 8px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  left: 0;
  background: #29371c;

  .footer-group {
    display: flex;
    width: 360px;
    align-items: flex-start;
    gap: 16px;
    .cancel-button {
      background: #fff;
      color: black;
    }
  }
}
